import React, { useState } from 'react';
import { Link } from 'gatsby';
import LogoIcon from '../../svg/LogoIcon';

function Logo() {
  const [mainColour, setColour] = useState('#991A5F');

  return (
    <Link to="/">
      <div
        className="flex items-center text-xl md:text-2xl"
        onMouseOver={() => setColour('#841651')}
        onMouseOut={() => setColour('#991A5F')}
      >
        <div className="w-20 md:w-28">
          <LogoIcon fill={mainColour} />
        </div>
        Jobs in Medicine
      </div>
    </Link>
  );
}

export default Logo;
