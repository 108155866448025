import React from 'react';
import { Link } from 'gatsby';

const Footer = () => (
  <footer className="container mx-auto py-16 px-6 mt-0 mb-8 text-gray-800 overflow-hidden">
    <div className="grid grid-cols-2 sm:grid-cols-3 gap-8">
      <div>
        <h2 className="text-lg font-semibold">Über uns</h2>
        <p className="mt-5">
          Wir sind Basil, Maurice und Nils. Mit MorrowMed möchten wir unser Gesundheitssystem
          verbessern und digitale Lösungen schaffen, die Arbeit für Alle erleichtert.
        </p>
      </div>
      <div>
        <h2 className="text-lg font-semibold">Sonstiges</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <Link to="/career">Karriere bei uns</Link>
          </li>
          <li>
            <Link to="/impressum">Impressum</Link>
          </li>
          <li>
            <Link to="/privacy">Datenschutz</Link>
          </li>
          <li>
            <Link to="/user-agreements">Nutzungsbedingungen</Link>
          </li>
        </ul>
      </div>
      <div>
        <h2 className="text-lg font-semibold">Kontakt</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="mailto:kontakt@morrowmed.de">kontakt@morrowmed.de</a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
