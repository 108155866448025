import React from 'react';
import { Helmet } from 'react-helmet';

const SiteMetadata = ({ pathname }) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>MorrowMed</title>
      <link rel="canonical" href={`https://www.morrowmed.de/${pathname}`} />
      <meta name="author" content="MorrowMed" />
      <meta name="description" content="Für die Medizin von morgen." />
      <meta
        name="keywords"
        content="JiM, Medizin, Job, Studium, Nebenjob, Gesundheitswesen, Würzburg, Krankenhaus, Klinik"
      />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
    </Helmet>
  );
};

export default SiteMetadata;
