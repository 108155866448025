import React from 'react';

const LogoIcon = ({ fill = '#991a5f' }) => (
  <svg width="100%" viewBox="0 0 2000 2000">
    <path
      d="M6240 16149c-953-60-1633-411-2012-1039-219-363-340-800-378-1365-13-205-13-7285 0-7490 14-199 35-373 66-525 167-838 603-1388 1324-1673 290-114 624-182 1025-207 218-14 7252-14 7470 0 904 57 1538 348 1938 892 279 380 434 869 477 1513 13 205 13 7285 0 7490-14 199-35 373-66 525-167 838-603 1388-1324 1673-290 114-624 182-1025 207-192 12-7305 11-7495-1zm3330-4244v-395h-870v790h870v-395zm-1722-1195c3-1630 1-1741-39-1964-91-509-383-831-859-950-190-48-318-60-578-53-309 7-511 47-697 136-233 111-346 228-470 486-67 140-108 265-140 425-37 193-54 356-57 555l-3 180 436 3 436 2 7-162c15-380 64-619 151-729 43-55 121-103 205-124 79-21 263-23 340-5 161 37 263 137 304 296 34 131 36 255 36 1870v1604l463-2 462-3 3-1565zm4357-167c220-952 402-1729 404-1727s182 781 400 1731l397 1728 667 3 667 2-2-2217-3-2218h-860v1839c1 1011-1 1834-2 1830-1-5-187-828-413-1829s-412-1826-415-1832c-3-10-102-13-449-13-245 0-447 3-449 8-3 4-188 824-411 1822-224 998-410 1825-414 1838-4 12-7-807-7-1820V7845l-427-3-428-2v4440l672-2 672-3 401-1732zM9570 9480V7840l-432 2-433 3-3 1638-2 1637h870V9480z"
      transform="matrix(.1 0 0 -.1 0 2000)"
      fill={fill}
      className="transition duration-200"
    />
  </svg>
);

export default LogoIcon;

/*
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
   width= "120px" height="120px"viewBox="0 0 2000.000000 2000.000000"
   preserveAspectRatio="xMidYMid meet">
  <g transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)"
  fill={props.mainColour} stroke="none">
  <path d="M6240 16149 c-953 -60 -1633 -411 -2012 -1039 -219 -363 -340 -800
  -378 -1365 -13 -205 -13 -7285 0 -7490 14 -199 35 -373 66 -525 167 -838 603
  -1388 1324 -1673 290 -114 624 -182 1025 -207 218 -14 7252 -14 7470 0 904 57
  1538 348 1938 892 279 380 434 869 477 1513 13 205 13 7285 0 7490 -14 199
  -35 373 -66 525 -167 838 -603 1388 -1324 1673 -290 114 -624 182 -1025 207
  -192 12 -7305 11 -7495 -1z m3330 -4244 l0 -395 -435 0 -435 0 0 395 0 395
  435 0 435 0 0 -395z m-1722 -1195 c3 -1630 1 -1741 -39 -1964 -91 -509 -383
  -831 -859 -950 -190 -48 -318 -60 -578 -53 -309 7 -511 47 -697 136 -233 111
  -346 228 -470 486 -67 140 -108 265 -140 425 -37 193 -54 356 -57 555 l-3 180
  436 3 436 2 7 -162 c15 -380 64 -619 151 -729 43 -55 121 -103 205 -124 79
  -21 263 -23 340 -5 161 37 263 137 304 296 34 131 36 255 36 1870 l0 1604 463
  -2 462 -3 3 -1565z m4357 -167 c220 -952 402 -1729 404 -1727 2 2 182 781 400
  1731 l397 1728 667 3 667 2 -2 -2217 -3 -2218 -430 0 -430 0 0 1839 c1 1011
  -1 1834 -2 1830 -1 -5 -187 -828 -413 -1829 -226 -1001 -412 -1826 -415 -1832
  -3 -10 -102 -13 -449 -13 -245 0 -447 3 -449 8 -3 4 -188 824 -411 1822 -224
  998 -410 1825 -414 1838 -4 12 -7 -807 -7 -1820 l0 -1843 -427 -3 -428 -2 0
  2220 0 2220 672 -2 672 -3 401 -1732z m-2635 -1063 l0 -1640 -432 2 -433 3 -3
  1638 -2 1637 435 0 435 0 0 -1640z"/>
  </g>
  </svg>
*/
